import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Hours = () => (
  <Layout>
    <SEO title="Hours and Location" />
    <br/>
    <h2 style={{textAlign: "center"}}>Aladdin International Food Market is located at 15503 Babcock RD,
San Antonio, TX 78255.</h2>
    {/*<div className="map">
            <MapContainer className="mx-auto"/></div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/>
            <br/>
            <br/>
            <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBukd87v6zotU8qdlizMHj46Dg0kK5HOA8' }}
          defaultCenter={
            {
              lat: 59.95,
              lng: 30.33
            }
          }
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>*/}
      <p align="center"
      style={{
        position: "relative",
        overflow: "hidden",
        paddingTop: "56.25%"
      }}
      >
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.518948434341!2d-98.6336758840454!3d29.588596946915025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c666e1f7e2179%3A0x6d4be0a1239fb843!2s15503%20Babcock%20Rd%2C%20San%20Antonio%2C%20TX%2078255!5e0!3m2!1sen!2sus!4v1602197203801!5m2!1sen!2sus" 
      frameborder="0" 
      style={{
        border:0,
        position: "absolute",
        top: 0,
        left: 0,
        width:"100%",
        height: "100%",
      }} 
      allowFullScreen="" 
      aria-hidden="false" 
      tabindex="0"></iframe></p>
    {/*<h2 style={{textAlign: "center"}}>Our hours are: <br/>
    Weekdays: 9:00-9:00
    <br/>
    Saturdays: 9:00-9:00<br/>
    Sundays: 9:00 - 9:00</h2>*/}
    <h2 style={{textAlign: "center"}}>We are open from 8:00 AM to 8:00 PM every day</h2>
  </Layout>
)

export default Hours
